.mainContainer {
  height: calc(100% - 6.5%);
  /* max-width: 1200px; */
  margin: 10px 0px auto;
  width: 100%;
  background-color: #fff;
  /* background-color: #fafafa; */
  border: 1px solid #ccc;
  border-radius: 12px;
  overflow: hidden;
  overflow-y: scroll;
}

.heightReduce {
  height: calc(100% - 15%);
}

.topRadius {
  border-top-left-radius: 0px;
  border-top-right-radius: 3px;
}
