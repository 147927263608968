@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

:root {
  --primary-color: #2087f5;
  --color-white: #fff;
  --color-black: #000;
  --text-color: #5f646c;
  --color-text-black: rgb(12, 12, 12);
  --button-gradient: linear-gradient(
    to right,
    rgb(255, 226, 193) 0%,
    rgb(255, 186, 181) 100%
  );
  --primary-font: "Outfit", sans-serif;
  --weight1: 700;
  --weight2: 600;
  --weight3: 500;
  --weight4: 400;
  --border-radius: 8px;
  --border-radius-large: 12px;
}

html {
  scroll-behavior: smooth;
  line-height: 1.5;
}

body {
  z-index: 1;
  position: relative;
  /* font-size: 1rem; */
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  font-family: var(--primary-font);

  /* background: var(--gradient);
  background-repeat: no-repeat; */
  background-color: var(--background);
}

.note {
  font-size: 13px;
  color: var(--text-color);
}

/* colors
primary - #2087F5

small text color - #5F646C;

Font family - 'Outfit'

Font size - '
- Heading - 28px
- normal text - 17px
- normal text 2 - 15px 
- small text - 11px

font weight 
- 300
- 400 
- 500
- 600
- 700
*/

ul,
ol {
  margin-left: 15px;
}

/* Capitalize */
h1:first-letter,
h2:first-letter,
h3:first-letter,
h4:first-letter,
h5:first-letter,
h6:first-letter,
p:first-letter,
.first-letter-uppercase:first-letter {
  text-transform: uppercase !important;
}

.w-700 {
  font-weight: var(--weight1);
}

.w-600 {
  font-weight: var(--weight2);
}

.w-500 {
  font-weight: var(--weight3);
}

.w-400 {
  font-weight: var(--weight4);
}

/* network detector css */
.internet-error {
  width: 100%;
  position: fixed;
  height: 60px;
  background: var(--button-gradient);
  margin-top: 0;
  font-size: 20px;
  z-index: 1000;
}

.internet-error p {
  font-size: 22px;
  line-height: 60px;
  color: var(--color-text-black);
  margin: 0;
}

a {
  font-size: 15px;
  color: black;
}

.mb-10 {
  margin-bottom: 10px !important;
}

/* scroll bar styles */
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
