.button {
  align-items: center;
  border-radius: var(--border-radius-large);
  border: none;
  box-shadow: 0px 2px 7px 0px var(--button-shadow-color);
  color: var(--color-text-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  overflow: hidden;
  padding: 2rem 2rem;
  transition: all 0.2s;
  width: 330px;
  text-transform: uppercase;
  cursor: pointer;
}

.icon {
  flex: 20%;
  font-size: 2rem;
  font-weight: 900;
  text-align: right;
}

.withBackground {
  background: var(--button-gradient);
}

.noBackground {
  background-color: transparent;
  border: 4px solid var(--theme-color2);
}

.buttonText {
  flex: 80%;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: var(--primary-font);
  text-align: left;
}
