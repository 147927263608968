/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container > * {
  margin: 1rem 0;
} */

.headingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

/* .title {
  margin: 0px;
  font-size: 28px;
  font-weight: 500;
}

.subtitle {
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-color);
} */
