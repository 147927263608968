.sideNav {
  /* sticky sidebar */
  /* position: fixed;
  top: 6rem; */
  height: 100vh;
  width: 16rem;
  /* max-width: 100%; */
  /* padding: 30px 0px; */
  color: var(--color-white);
  background-color: var(--color-text-black);
  /* border-top-right-radius: 12px; */
}

.logoHeader {
  display: flex;
  /* justify-content: space-around; */
  justify-content: flex-start;
  align-items: center;
}

/* .menuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.logOutWrapper {
  border-top: 1px solid var(--dark-border-color);
  margin-bottom: 20px;
}

.menuList,
.logOutWrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.menuList .menuItem,
.logOutWrapper .menuItem {
  color: var(--color-white);
  text-decoration: none;
  font-size: 17px;
  font-weight: 400;
  font-family: var(--primary-font);
  padding: 20px;

  display: flex;
  align-items: center;
}

.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-right: 15px;
}

.active {
  width: 90%;
  border-radius: 0px 8px 8px 0px;
  background-color: var(--primary-color);
  color: var(--color-white) !important;
}

@media screen and (min-width: 1400px) {
  .sideNav {
    width: 20rem;
  }
}
